.background {
    width: 100vw;
    height: 100vh;
    background-color: white;
}
.container {
    width: 37.5rem;
    margin: 3.5rem auto 0.5rem;
}
.logoContainer {
    margin: 8.125rem 0 5.625rem;
    text-align: center;
}
.header {
    margin: 1.25rem 0 1.5625rem 6.25rem;
}
.header > h1 {
    font-size: 1.5625rem;
    font-weight: 400;
}
.form {
    margin: 0 auto;
}
.formField {
    padding-left: 6.25rem;
}
.submitButton {
    margin: 3.125rem auto;
    display: flex;
    justify-content: center;
    color: white;
}
.buttonText {
    padding-right: 0.625rem;
    font-size: 0.9375rem;
}
.devLoginButton {
    color: black;
    font-size: 1.25rem !important;
    padding: 0 !important;
}
.devButtonContainer {
    position: absolute;
    top: 20px;
    right: 20px;
}
