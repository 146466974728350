* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 100% !important;
}

body {
  font-size: inherit;
  margin: 0;
  font-family: "Mukta", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

button.ui.circular.icon.button {
  width: 2.1875rem;
  min-width: 2.1875rem;
  color: white;
  background-color: #143386;
}
button.ui.circular.blue.button {
  color: white;
  background-color: #143386;
  font-family: "Mukta", sans-serif !important;
  font-size: 0.875rem;
  letter-spacing: normal;
  font-weight: normal;
}
div.ui {
  font-family: "Mukta", sans-serif !important;
  letter-spacing: normal;
  font-weight: normal;
}

.ui.secondary.pointing.menu {
  padding: 0 6.875rem;
  border-bottom: 1px solid #dce0e0 !important;
  font-size: 1rem;
}
.ui.secondary.pointing.menu .active.item {
  border-color: #143386 !important;
  color: #143386 !important;
}
.ui.segment.active.tab {
  box-shadow: none;
  border: none;
}

.ui.checkbox label {
  font-size: 1rem !important;
}

div.ui.label {
  font-family: "Mukta", sans-serif;
  letter-spacing: normal;
  font-weight: normal;
}

div.ui.mini.right.labeled.input {
  height: 1.875rem;
  font-size: 0.75rem !important;
}

div.ui.horizontal.label {
  min-width: 74px;
  min-height: 30px;
  text-align: center;
  font-size: 0.75rem !important;
}

div.inline.thirteen.wide.field {
  padding-right: 0 !important;
}
.mockButton {
  margin: 0.4375rem;
  border: none;
  height: 2.1875rem;
  min-width: 4.0625rem;
  border-radius: 1.09375rem;
  font-family: "Mukta";
  font-size: 0.875rem;
  padding: 0.625rem 1.25rem;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mockButton.grey {
  background-color: #ededed;
}
.mockButton.blue {
  background-color: #143386;
  color: white;
}
button.ui.button {
  margin: 0.4375rem;
  border: none;
  height: 2.1875rem;
  min-width: 4.0625rem;
  border-radius: 1.09375rem;
  font-family: "Mukta";
  font-size: 0.875rem;
  font-weight: normal;
  padding: 0.625rem 1.25rem;
  text-align: center;
  cursor: pointer;
}
button.ui.button.primary {
  background-color: #143386;
  color: white;
}

button.ui.button.primary:hover {
  background-color: #0e245e;
}

h1 {
  font-size: 1.625rem !important;
}
h1.ui.header {
  font-size: 1.625rem;
  margin-bottom: 1rem !important;
}
div .sub.header {
  margin-top: 1rem !important;
  font-size: 1rem !important;
}

textarea {
  font-family: "Mukta", sans-serif !important;
}

.ui.accordion .title:not(.ui) {
  font-family: "Mukta", sans-serif !important;
}

.ui.form .field > label {
  font-weight: 500 !important;
}
