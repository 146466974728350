.willNotParticipateContainer {
    margin: 1rem 0 1rem 6rem;

    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
}

.willNotParticipateContainer button {
    font-size: 1.25rem;
    transform: translateY(-3px);
    background: none;
    padding: 0;
}



@media screen and (max-device-width: 1420px) {
    .willNotParticipateContainer {
        margin: 1rem 0 1rem 3rem;
    }
}
