.outerWrapper {
  padding: 0 !important;
}

.innerWrapper {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 1.2rem;
  border-bottom: 1px solid #f7f7f7;
  cursor: default;
}

.left {
  padding: 1.5625rem 25rem 1.5625rem 6.25rem;
  width: 62.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.rightOuter {
  padding: 1.5625rem 1.875rem 1.5625rem 3.125rem;
  align-self: flex-end;
  flex-basis: 15.625rem;
}

.rightInner {
  margin-left: 1.25rem;
}

@media screen and (max-device-width: 1420px) {
  .left {
    padding: 1.5625rem 20rem 1.5625rem 3rem;
    width: 65rem;
  }
}
