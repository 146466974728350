.container {
  max-width: 68.75rem;
  min-width: 50rem;
  min-height: 31.25rem;
  background-color: #ffffff;
  padding: 3.125rem;
}

.form {
  width: 31.25rem;
  padding: 1.25rem;
  margin: 1.25rem auto;
}
.submitButton {
  display: flex;
  justify-content: center;
}

.checkboxHeader {
  padding-bottom: 0.625rem;
}
.radioGroupContainer {
  width: 50rem;
  display: flex;
  justify-content: flex-start;
}
.radioGroupItem {
  padding: 0 2rem 1rem 0;
}
h1 {
  font-size: 1.625rem;
}

h2 {
  font-size: 1.2rem;
}

.cancel {
  color: black;
}
