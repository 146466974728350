.costBreakdown {
  display: flex;
  justify-content: space-between;
  min-height: 6.25rem;
  padding: 1.25rem 1.875rem 1.25rem 6.25rem;
  border-top: 1px solid #f7f7f7;
}

.costBreakdownHeader {
  font-weight: 500;
  font-size: 1.2rem;
  font-family: "Mukta", sans-serif;
}

.costBreakdownRow {
  display: flex;
  flex-direction: column;
  width: 35rem;
}

.costBreakdownItem {
  display: flex;
  justify-content: space-between;
  margin: 0.625rem;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
}

.currencyIcon {
  padding-left: 0.3125rem;
}

@media screen and (max-device-width: 1420px) {
  .costBreakdown {
    padding: 1.25rem 1.875rem 1.25rem 3rem;
  }
}
