button {
  margin: 0.4375rem;
  border: none;
  height: 2.1875rem;
  min-width: 4.0625rem;
  border-radius: 1.09375rem;
  font-family: "Mukta";
  font-size: 0.875rem;
  padding: 0.625rem 1.25rem;
  text-align: center;
  cursor: pointer;
}
button:focus {
  outline: none;
}
.buttonBlue {
  background-color: #143386;
  color: white;
}

.buttonGrey {
  background-color: #ededed;
}

.smallButton {
  background-color: #143386;
  color: white;
  height: 1.6875rem;
  border-radius: 0.84375rem;
  text-align: center;
  padding: 2px 14px;
}
.roundButton {
  width: 2.1875rem;
  min-width: 2.1875rem;
  margin: 0;
  padding: 0;
  font-size: 1.5625rem;
  font-weight: bold;
  background-color: #143386;
  color: white;
}
.roundButton:focus {
  outline: none;
}
