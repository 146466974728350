.container {
  /* max-width: 68.75rem;
  min-width: 50rem; */
  width: 65rem;
  min-height: 37.5rem;
  display: flex;
  flex-direction: column;
  padding-top: 1.25rem;
  background-color: white;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6.875rem 1.25rem 6.875rem;
}

.header > h1 {
  font-size: 1.625rem;
}
