.wrapper {
  display: flex;
  padding-bottom: 0.9375rem;
  padding-left: 1.25rem;
  width: 80rem;
  max-width: 80rem;
}

.title {
  align-self: center;
  min-width: 0;
  flex-basis: 15.625rem;
}

.right {
  min-width: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 0 1 62.5rem;
}

@media screen and (max-device-width: 1420px) {
  .right {
    flex: 0 1 61rem;
  }
}
