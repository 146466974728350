.App {
  height: 100%;
}
.main-container {
  background-color: #f7f7f7;
  display: flex;
  justify-content: center;
  /* 5rem is the height of the header and if the header is changed this should be too */
  min-height: calc(100vh - 5rem);
}

.app-container, header {
  z-index: 999;
}
