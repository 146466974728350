.wrapper {
  padding: 0 1.875rem 0 6.25rem;
  display: flex;
  justify-content: space-between;
  margin: 1.25rem 0;
  align-items: center;
}

.left {
  margin-right: auto;
  display: flex;
  flex-direction: column;
}

.projectNumber {
  font-size: 1.125rem;
}

@media screen and (max-device-width: 1420px) {
  .wrapper {
    padding: 0 1.875rem 0 3rem;
  }
}
