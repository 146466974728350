.wrapper {
  border-top: 1px solid #f7f7f7;
  background-color: white;
  width: 88.75rem;
}
@media screen and (max-device-width: 1420px) {
  .wrapper {
    width: 80rem;
  }
}
