.input {
  min-width: 7.5rem;
  width: 10rem;
}
.perContainer {
  min-width: 0;
  width: 18.75rem;
  display: flex;
  justify-content: center;
}

.totalContainer {
  min-width: 0;
  display: flex;
  justify-content: flex-start;
  width: 12.5rem;
}

.or {
  flex: 0 0 3.125rem;
  align-self: center;
  padding: 0 1.875rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #113686;
  text-align: center;
}
.totalInputUI4 {
  display: flex;
  min-width: 0;
  justify-content: flex-start;
  width: 12.5rem;
}
