.wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.25rem 0;
  width: 88.75rem;
  background-color: white;
  margin-bottom: 3.125rem;
}

@media screen and (max-device-width: 1420px) {
  .wrapper {
    width: 80rem;
    align-self: flex-start;
  }
}
