.pdfButton {
  background-color: #ededed !important;
  color: black !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8rem;
}

.headerRow {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.lockContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 2rem;
}
