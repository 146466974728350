.container {
  width: 80rem;
  padding: 0 1.875rem 0 1.25rem;
}
.textArea {
  line-height: 1.21428571em !important;
  padding: 0.5em 1em !important;
  font-size: 1em !important;
  height: 30px;
}
