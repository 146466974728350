.offerListContainer {
  padding: 1.25rem 6.875rem 1.25rem 6.875rem;
  display: flex;
  justify-content: center;
  width: 100%;
}

.offerList {
  list-style-type: none;
  width: 44rem;
}
.listItem {
  border-radius: 3px;
  border: solid 1px #dce0e0;
  margin-bottom: 0.5rem;
  align-items: space-around;
}
.offer {
  display: flex;
  align-items: center;
  width: 100%;
}
.offer:hover {
  background-color: #f9f9f9;
}
.icon {
  padding: 1.4375rem 2.375rem 1.8125rem 2.1875rem;
}
.titleContainer {
  font-size: 1.25rem;
  color: black;
  line-height: 1.3;
  padding: 1rem 1rem 1rem 0;
}
.publishedListItem {
  border-radius: 3px;
  border: solid 1px #dce0e0;
  margin-bottom: 0.5rem;
}

.projectNum {
  padding-right: 0.625rem;
}
.statusDate {
  padding-right: 1.25rem;
  margin-left: auto;
  font-size: 0.875rem;
}
.status {
  padding: 0 0.625rem;
}
