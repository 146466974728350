.wrapper {
  display: flex;
  flex-direction: column;
  padding: 1.5625rem 1.875rem 1.5625rem 6.25rem;
  width: 100%;
}

@media screen and (max-device-width: 1420px) {
  .wrapper {
    padding: 1.5625rem 0 1.5625rem 1.75rem;
  }
}
